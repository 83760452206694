// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'

export const register = createAsyncThunk('appUsers/createUser', async (user, { rejectWithValue }) => {
  try {
    const response = await axios.post('/users/create', user)
    return { user: response.data.users }
  } catch (ex) {
    throw rejectWithValue(ex.response)
  }
})

export const inviteregister = createAsyncThunk('appUsers/inviteregister', async (user, { rejectWithValue }) => {
  try {
    const response = await axios.post('/users/inviteregister', user)
    return { user: response.data.users }
  } catch (ex) {
    return rejectWithValue(ex.response)
  }
})

export const generateCode = createAsyncThunk('appUsers/sendCode', async (data, { }) => {
  const response = await axios.post('/users/sendCode', data)
  return response
})

export const verfiyCode = createAsyncThunk('appUsers/verfiyCode', async (data, { rejectWithValue }) => {
  try {
    const response = await axios.post('/users/validatecode', data)
    return response
  } catch (ex) {
    throw rejectWithValue(ex.response)
  }

})

export const listOrg = createAsyncThunk('appUsers/listOrg', async (data, { }) => {

  const response = await axios.post('/clientaccessorganization/list', data)
  const org = response.data.organizations[0] ?? {}
  localStorage.setItem('defaultOrganization', JSON.stringify(org))
  if (org?.clientId) {
    localStorage.setItem('clientId', JSON.stringify(org?.clientId))
  }
  return { organizations: response.data.organizations }

})

export const getClientData = createAsyncThunk('appUsers/getClientData', async (data, { }) => {

  const response = await axios.post('/clientaccessorganization/getClient', data)

  return response.data.organization

})

export const createOrganization = createAsyncThunk('appUsers/createOrganization', async (organization, { rejectWithValue }) => {
  try {
    const response = await axios.post('/organizations/create', organization)
    return { organization: response.data.organizations }
  } catch (ex) {
    console.log(ex.response)
    return rejectWithValue(ex.response)
  }
})

export const createOrganizationPlanPackage = createAsyncThunk('appUsers/createOrganizationPlanPackage', async (data, { rejectWithValue }) => {
  try {
    await axios.post('/organizations/createplanpackage', data)
    return
  } catch (ex) {
    console.log(ex.response)
    return rejectWithValue(ex.response)
  }
})

export const createOrganizationUser = createAsyncThunk('appUsers/createOrganizationUser', async (organization, { }) => {
  const response = await axios.post('/organizationusers/create', organization)
  return { organizationusers: response.data.organizationusers }
})

export const GSTVerify = createAsyncThunk('appUsers/GSTVerify', async (gstin, { }) => {
  const response = await axios.post('/gstapi/verifyTaxPayer', gstin)
  return { taxPayer: response.data.taxpayer }
})

export const updateAbility = createAsyncThunk('appUsers/updateAbility', async (data, { }) => {
  return data
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    loginUser: null,
    loginError: null,
    verifyprocess: false,
    verifyError: null,
    defaultOrganization: null,
    defaultOrganizationId: null,
    accessToken: '',
    organizationList: [],
    organizationCreateError: null,
    registerSuccess: false,
    roleAbility: [],
    taxpayer: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(inviteregister.fulfilled, (state, action) => {
        const data = action.payload.user
        state.loginUser = data
      })
      .addCase(inviteregister.rejected, (state, action) => {
        state.loginError = action.payload.data.errors
      })
      .addCase(register.fulfilled, (state, action) => {
        const data = action.payload.user
        data.role = 'admin'
        data.ability = [
          {
            action: 'manage',
            subject: 'all'
          }
        ]
        state.loginUser = data
      })
      .addCase(register.rejected, (state, action) => {
        state.loginError = action.payload.data.errors
      })
      .addCase(updateAbility.fulfilled, (state, action) => {
        state.roleAbility = action.payload
      })
      .addCase(listOrg.fulfilled, (state, action) => {
        state.organizationList = action.payload.organizations
      })
      .addCase(getClientData.fulfilled, (state, action) => {      
        state.defaultOrganization = action.payload
      })
      .addCase(verfiyCode.fulfilled, (state, action) => {
        state.verifyprocess = true
        state.loginUser = action.payload.data.users
        state.accessToken = action.payload.data.token
      })
      .addCase(verfiyCode.rejected, (state, action) => {
        state.verifyprocess = null
        state.verifyError = action.payload.data.errors
      })
      .addCase(GSTVerify.fulfilled, (state, action) => {
        state.taxpayer = action.payload.taxPayer
      })
      .addCase(createOrganization.fulfilled, (state, action) => {
        state.defaultOrganizationId = action.payload.organization.id
      })
      .addCase(createOrganization.rejected, (state, action) => {
        state.organizationCreateError = action.payload.data.errors
      })
      .addCase(createOrganizationUser.fulfilled, (state, action) => {
        state.defaultOrganization = action.payload.organizationusers
        state.registerSuccess = true
      })

  }
})

export default appUsersSlice.reducer
