// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'

export const getData = createAsyncThunk('appPasswords/getData', async params => {
  const response = await axios.post('/passwordmanage/list', params)
  return {
    params,
    data: response.data.passwordmanage    
  }
})

export const applyFilter = createAsyncThunk('appPasswords/applyFilter', async (val, { }) => {
  
  return val
})

export const setActiveFilter = createAsyncThunk('appPasswords/setActiveFilter', async (val, { }) => {
  
  return val
})


export const appPasswordSlice = createSlice({
  name: 'appPasswords',
  initialState: {
    data: [],
    total: 1,
    params: {},
    filter : null,
    activefilter: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
      })      
      .addCase(applyFilter.fulfilled, (state, action) => {
        state.filter = action.payload
      })
      .addCase(setActiveFilter.fulfilled, (state, action) => {
        state.activefilter = action.payload
      })   
  }
})

export default appPasswordSlice.reducer
