// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import notifications from './notifications'

import chat from '@src/views/apps/chat/store'
import contacts from '@src/views/apps/contacts/store'
import passwords from '@src/views/apps/passwords/store'
import documents from '@src/views/apps/documents/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import communication from '@src/views/apps/task/store/communication'

import team from '@src/views/pages/account-settings/team/store'
import task from '@src/views/apps/task/store'
import taskrequirements from '@src/views/apps/task/store/taskrequirements.js'
import taskconversation from '@src/views/apps/task/store/taskconversation.js'
import invoicepayment from '@src/views/apps/invoice/InvoicePayment/store/index.js'

import register from '@src/views/pages/authentication/register-multi-steps/store'
import client from '@src/views/apps/client/store'
import digitalsignature from '@src/views/apps/digital-signature/store'
import dsclicense from '@src/views/apps/dsc-license/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'

import importExcel from '@src/views/components/import/store'

import dashboard from '@src/views/dashboard/ecommerce/store/index.js'
import profile from '@src/views/apps/profile/store/index.js'
import organization from '@src/views/pages/account-settings/store/index.js'
import licenseList from '@src/views/apps/dsc-license/store/index.js'
import compliance from '@src/views/apps/compliance/store/index.js'
import organizationmain from '@src/views/apps/organization/store/index.js'
import role from '@src/views/pages/account-settings/RolesAndDesignation/store/roles.js'
import gstapi from '@src/views/apps/gstfiling/store/index.js'
const rootReducer = {
  auth,
  chat,  
  email,
  users,
  navbar,
  invoicepayment,
  notifications,
  layout, 
  client,
  contacts,
  passwords,
  documents,
  register,
  invoice,
  communication,
  digitalsignature,
  dsclicense,
  task,
  calendar,
  ecommerce,
  dataTables,  
  team,  
  taskrequirements,
  taskconversation,
  dashboard,
  profile,
  organization,
  licenseList,
  compliance,
  organizationmain,
  importExcel,  
  role,
  gstapi
}

export default rootReducer
