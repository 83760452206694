// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'

export const getDocuments = createAsyncThunk('appDataroom/getDocuments', async (params, { }) => {
  const res = await axios.post('/documentsinout/list', params)
  return { data: res.data.documentsinout, params }
})

export const applyFilter = createAsyncThunk('appDataroom/applyFilter', async (val, { }) => {
  return val
})

export const setActiveFilter = createAsyncThunk('appDataroom/setActiveFilter', async (val, { }) => {
  return val
})

export const appDataroomSlice = createSlice({
  name: 'appDataroom',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDigitalSignature: null,
    DSCLists: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getDocuments.fulfilled, (state, action) => {
    state.documentList = action.payload.data
    state.documentParams = action.payload.params    
  })
  .addCase(applyFilter.fulfilled, (state, action) => {
    state.filter = action.payload
  })
  .addCase(setActiveFilter.fulfilled, (state, action) => {
    state.activefilter = action.payload
  })
  }
})

export default appDataroomSlice.reducer
