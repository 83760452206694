// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()
const statusOptions = ['', 'Active', 'In Active']
export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.post(`/clients/list`, params)
  return {
    params,
    data: response.data.clients.clients,
    totalPages: response.data.clients.total
  }
})

export const exportClient = createAsyncThunk('appUsers/exportClient', async params => {
  const response = await axios.post(`/clients/exportToExcel`, params)

  const result = await response.data.clients.map((obj) => {

    const data = {}
    data['name'] = obj.name
    data['contactperson'] = obj.contactpersonname
    data['email'] = obj.email
    data['contact'] = obj.contactnumber
    data['businessentity'] = obj.businessentityname
    data['status'] = statusOptions[obj.status]

    return data
  })

  const ws = await XLSX.utils.json_to_sheet(result, { origin: 'A2', skipHeader: true })
  const Heading = [['Name', 'Contact Person', 'Email', 'Contact', 'Business Entity', 'Status']]
  XLSX.utils.sheet_add_aoa(ws, Heading)
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
  FileSaver.saveAs(data, 'clients.xlsx')

})

export const refreshClient = createAsyncThunk('appUsers/refreshClient', async data => {
  const res = await axios.post(`/clients/refreshClient`, data)

  Cookies.set('defaultOrganization', res.data.organization, {domain : process.env.REACT_APP_PARENTDOMAIN})
})


export const getClient = createAsyncThunk('appClients/getClient', async id => {
  const response = await axios.post('/clients/get', { id })
  return response.data.clients
})

export const addClient = createAsyncThunk('appClients/addClient', async (client, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/clients/create`, client)
    return { client: response.data.clients }
  } catch (ex) {
    return rejectWithValue(ex.response)
  }
})

export const updateClient = createAsyncThunk('appClients/updateClient', async (client, { }) => {
  const response = await axios.post(`/clients/update`, client)
  return { client: response.data.clients }
})

export const addContactInfo = createAsyncThunk('appClients/addContactInfo', async (rows, { }) => {
  const response = await axios.post(`/contactinformations/create`, rows)
  return { clientInfos: response.data.clients }
})

export const updateContactInfo = createAsyncThunk('appClients/updateContactInfo', async (rows, { }) => {
  const response = await axios.post(`/contactinformations/update`, rows)
  return { clientInfos: response.data.clients }
})

export const getConatctInfo = createAsyncThunk('appClients/getClientInfo', async (params) => {
  const response = await axios.post(`/contactinformations/list`, params)
  return {
    clientInfos: response.data.contactinformations,
    params
  }
})

export const getConatctMutualInfo = createAsyncThunk('appClients/getConatctMutualInfo', async (params) => {
  const response = await axios.post(`/contactinformations/mutuallist`, params)
  return { clientInfos: response.data.contactinformations, params }
})

export const deleteClient = createAsyncThunk('appClients/deleteClient', async (id, { dispatch, getState }) => {
  await axios.post('/clients/delete', { id, updatedBy: userId })
  await dispatch(getData(getState().client.params))
  return id
})

export const deleteContactInfo = createAsyncThunk('appClients/deleteContactInfo', async (id, { }) => {
  await axios.post('/contactinformations/delete', { id, updatedBy: userId })
  return null
})

export const updateStatus = createAsyncThunk('appClients/updateStatus', async (data, { dispatch, getState }) => {
  await axios.post(`/clients/statusupdate`, data)
  console.log(getState().client)
  await dispatch(getData(getState().client.params))
  return ''
})

export const updateBulk = createAsyncThunk('appClients/updateStatus', async (data, { dispatch, getState }) => {
  await axios.post(`/clients/updateselectedrecords`, { ...data, updatedBy: userId })
  await dispatch(getData(getState().client.params))
  return ''
})

export const clientPortalClone = createAsyncThunk('appChat/clientPortalClone', async (obj, { }) => {
  await axios.post('/clients/clone', obj)
  return ''
})

export const clientPortalPasswordClone = createAsyncThunk('appChat/clientPortalPasswordClone', async (obj, { }) => {
  await axios.post('/passwordmanage/clone', obj)
  return ''
})

export const clientPortalDocumentClone = createAsyncThunk('appChat/clientPortalDocumentClone', async (obj, { }) => {
  await axios.post('/documentsinout/clone', obj)
  return ''
})

export const getDocuments = createAsyncThunk('appChat/getDocuments', async (params, { }) => {
  const res = await axios.post('/documentsinout/list', params)
  return { data: res.data.documentsinout.documentsinout, params, totalPages: res.data.documentsinout.total }
})

export const applyFilter = createAsyncThunk('appChat/applyFilter', async (val, { }) => {
  return val
})

export const setActiveFilter = createAsyncThunk('appChat/setActiveFilter', async (val, { }) => {
  return val
})

export const getCompanyDetails = createAsyncThunk('appClients/getCompanyDetails', async () => {
  try {
    const response = await axios.post("/masterdata/get-company-details")
    return response.data
  } catch (error) {
    console.error("Error fetching company details:", error)
  }
})


export const appClientsSlice = createSlice({
  name: 'appClients',
  initialState: {
    data: [],
    total: 1,
    params: {},
    clientErrors: null,
    allData: [],
    selectedClient: null,
    clientId: null,
    clientInformations: [],
    mutualList: [],
    documentParams: {},
    groupParams: {},
    documentList: [],
    filter: null,
    activefilter: {},
    companyDetails: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getConatctInfo.fulfilled, (state, action) => {
        state.clientInformations = action.payload.clientInfos
        state.params = action.payload.params
      })
      .addCase(getConatctMutualInfo.fulfilled, (state, action) => {
        state.mutualList = action.payload.clientInfos
        state.groupParams = action.payload.params
      })
      .addCase(addClient.fulfilled, (state, action) => {
        state.clientId = action.payload.client.id
      })
      .addCase(addClient.rejected, (state, action) => {
        state.clientErrors = action.payload.data.errors
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.selectedClient = action.payload
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.documentList = action.payload.data
        state.documentParams = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(applyFilter.fulfilled, (state, action) => {
        state.filter = action.payload
      })
      .addCase(setActiveFilter.fulfilled, (state, action) => {
        state.activefilter = action.payload
      })
      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        state.companyDetails = action.payload
      })
  }
})

export default appClientsSlice.reducer
