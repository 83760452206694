// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@src/configs/axios/axiosConfig'


export const getConatctInfo = createAsyncThunk('appClients/getClientInfo', async (params) => {
  const response = await axios.post(`/contactinformations/list`, params)
  return {
    clientInfos: response.data.contactinformations,
    params
  }
})

export const applyFilter = createAsyncThunk('appChat/applyFilter', async (val, { }) => {
  return val
})

export const setActiveFilter = createAsyncThunk('appChat/setActiveFilter', async (val, { }) => {
  return val
})

export const appClientsSlice = createSlice({
  name: 'appClients',
  initialState: {
    data: [],    
    params: {},    
    activefilter: {},
    companyDetails: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
   
      .addCase(getConatctInfo.fulfilled, (state, action) => {
        state.clientInformations = action.payload.clientInfos
        state.params = action.payload.params
      })
      .addCase(applyFilter.fulfilled, (state, action) => {
        state.filter = action.payload
      })
      .addCase(setActiveFilter.fulfilled, (state, action) => {
        state.activefilter = action.payload
      })   
  }
})

export default appClientsSlice.reducer
