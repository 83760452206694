import io from "socket.io-client"
import { store } from '@store/store'
const host = process.env.REACT_APP_API_URL
const socket = io(host, { forceNew: true, origin: host, transports: ['websocket'] })
import { setChatNotification } from '@src/views/apps/chat/store/index.js'
const logo = require(`@src/assets/images/logo/logo.png`).default

export const addNewUser = (userid) => {
    socket.emit("newUser", userid)
}

export const addGroup = (userid) => {
    socket.emit("newGroup", userid)
}

export const sendMessage = (touserId, fromUserid, data) => {

    if (Array.isArray(touserid)) {
        touserid?.map((id) => {
            socket.emit("send-msg", { to: id, from: fromUserid, message: data })
        })

    } else {
        socket.emit("send-msg", { to: touserId, from: fromUserid, message: data })
    }
}

socket.on("msg-recieve", async (data) => {

    await store.dispatch(setChatNotification(data.message))
    new Notification('Turia Notification', { body: data.message.message, icon: logo })

})
