// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/configs/axios/axiosConfig'
import { orgUserId } from '@src/helper/sassHelper'
const userId = orgUserId()

export const invitationsList = createAsyncThunk('appUsers/invitationsList', async params => {
  const response = await axios.post('/invitations/list', params)
  return {
    params,
    data: response.data.invitations.invitations,
    totalPages: response.data.invitations.total
  }
})

export const getInvitation = createAsyncThunk('appUsers/getInvitation', async id => {
  const response = await axios.post('/invitations/get', { id })
  return response.data.invitations
})

export const inviteMail = createAsyncThunk('appUsers/inviteMail', async id => {
  await axios.post('/invitations/invitationmail', { id })
  return ''
})

export const userList = createAsyncThunk('appUsers/userList', async params => {
  const response = await axios.post('/organizationusers/list', params)
  return {
    params,
    data: response.data.organizationusers.organizationusers,
    totalPages: response.data.organizationusers.total
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.post('/organizationusers/get', { id })
  return response.data.organizationuser
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { rejectWithValue }) => {
  try {
    const res = await axios.post('/invitations/create', user)
    return res.data.invitation
  } catch (ex) {
    return rejectWithValue(ex.response)
  }
})


export const updateUser = createAsyncThunk('appUsers/updateUser', async (user, { }) => {
  const res = await axios.post('/organizationusers/update', user)
  return res.data.invitation
})

export const updateInvitation = createAsyncThunk('appUsers/updateInvitation', async (user, { }) => {
  const res = await axios.post('/invitations/update', user)
  return res.data.invitation
})

export const updateStatus = createAsyncThunk('appUsers/updateStatus', async (data, { dispatch, getState }) => {
  await axios.post('/organizationusers/updatestatus', data)
  await dispatch(userList(getState().team.params))
  return id
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.post('/organizationusers/delete', { id, updatedBy: userId })
  await dispatch(userList(getState().team.params))
  return id
})

export const deleteInvitation = createAsyncThunk('appUsers/deleteInvitation', async (id, { dispatch, getState }) => {
  await axios.post('/invitations/delete', { id, updatedBy: userId })
  await dispatch(invitationsList(getState().team.params))
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    invitedId :null,
    teamErrors: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(invitationsList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(userList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getInvitation.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.invitedId = action.payload?.id
      })
      .addCase(addUser.rejected, (state, action) => {
        state.teamErrors = action.payload.data.errors
      })
  }
})

export default appUsersSlice.reducer
